<template>
    <b-card>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <b-tabs pills>
                    <b-tab active>
                        <template #title>
                            <feather-icon icon="SlidersIcon"/>
                            <span>Rol Bilgileri</span>
                        </template>

                        <b-row class="mt-1">

                            <b-col cols="12">
                                <validation-provider #default="validationContext" name="Rol" rules="required">
                                    <b-form-group label="Rol">
                                        <b-form-input trim placeholder="Rol" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12">
                                <validation-provider #default="validationContext" name="Açıklama" rules="required">
                                    <b-form-group label="Açıklama">
                                        <b-form-input trim placeholder="Açıklama" v-model="dataInfo.detail" :state="getValidationState(validationContext)"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12">
                                <validation-provider #default="validationContext" name="Varsayılan" rules="required">
                                    <b-form-group label="Varsayılan" label-for="isDefault" :state="getValidationState(validationContext)">
                                        <v-select v-model="dataInfo.isDefault" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12">
                                <validation-provider #default="validationContext" name="Ana Rol" rules="required">
                                    <b-form-group label="Ana Rol" label-for="isCore" :state="getValidationState(validationContext)">
                                        <v-select v-model="dataInfo.isCore" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12">
                                <validation-provider #default="validationContext" name="Durum" rules="required">
                                    <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
                                        <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                        </b-row>

                    </b-tab>

                    <b-tab>
                        <template #title>
                            <feather-icon icon="LockIcon"/>
                            <span>Modül Yetkileri</span>
                        </template>
                        <b-card no-body class="border mt-1">
                            <b-table
                                    :items="dataInfo.authorizations"
                                    :fields="authorizationFields"
                                    class="mb-0"
                                    striped responsive bordered hover>
                                <template #cell(isAuthorized)="data">
                                    <b-form-checkbox :checked="data.item.isAuthorized" @change="changeAuthorizationValue(data.item)"/>
                                </template>
                            </b-table>
                        </b-card>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <feather-icon icon="ToolIcon"/>
                            <span>Menü Yetkileri</span>
                        </template>
                        <b-card no-body class="border mt-1">
                            <b-table
                                    :items="dataInfo.navigationMenuAuthorizations"
                                    :fields="navigationMenuAuthorizationsFields"
                                    class="mb-0"
                                    striped responsive bordered hover>
                                <template #cell(isAuthorized)="data">
                                    <b-form-checkbox :checked="data.item.isAuthorized" @change="changeNavigationMenuAuthorizationValue(data.item)"/>
                                </template>
                            </b-table>
                        </b-card>
                    </b-tab>

                </b-tabs>
                <action-buttons :back-route="'system-role-list'"/>
            </b-form>
        </validation-observer>
        <Overlay :busy="busy"></Overlay>
    </b-card>
</template>

<script>
import {
    BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BTabs, BTab, BTable, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/system/role/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";
import {$themeConfig} from '@themeConfig'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BFormInvalidFeedback,
        BOverlay,
        BTabs,
        BTab,
        BTable,
        BFormCheckbox,

        vSelect,
        ActionButtons,
        Overlay,

        ValidationProvider,
        ValidationObserver,
    },
    props: {},
    data() {
        return {
            required,
        }
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
        })

        const toast = useToast()
        const busy = ref(false)
        const dataInfo = ref({
            id: 0,
            name: '',
            detail: '',
            isCore: false,
            isDefault: false,
            isActive: true,
            authorizations: [],
            navigationMenuAuthorizations: [],
        })

        const onSubmit = () => {
            if (!$themeConfig.app.appIsDemo) {
                busy.value = true
                store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
                    toastMessage(toast, 'success', response.data.message)
                    router.push({name: 'system-role-list'})
                }).catch(error => {
                    toastMessage(toast, 'danger', error.response.data.message)
                }).finally(message => {
                    busy.value = false
                })
            }else{
                toastMessage(toast, 'danger', 'Demo kullanımda işlem yapılamaz!')
            }
        }

        const authorizationFields = [
            {key: 'controller', label: 'Modül'},
            {key: 'permissionName', label: 'İşlem'},
            {key: 'isAuthorized', label: 'Yetki'}
        ];

        const navigationMenuAuthorizationsFields = [
            {key: 'menuName', label: 'Menü Adu'},
            {key: 'parentMenuName', label: 'Üst Menü Adı'},
            {key: 'isAuthorized', label: 'Yetki'}
        ]

        const changeAuthorizationValue = (auth) => {
            dataInfo.value.authorizations.forEach(function callback(value, index) {
                if (value.id === auth.id) {
                    value.isAuthorized = !auth.isAuthorized
                }
            })
        }

        const changeNavigationMenuAuthorizationValue = (auth) => {
            dataInfo.value.navigationMenuAuthorizations.forEach(function callback(value, index) {
                if (value.id === auth.id) {
                    value.isAuthorized = !auth.isAuthorized
                }
            })
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation()

        busy.value = true
        store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
            dataInfo.value = response.data.data
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            if (error.response.status === 403) {
                router.push({name: 'system-role-list'})
            }
        }).finally(message => {
            busy.value = false
        })

        return {
            busy,
            dataInfo,
            refFormObserver,
            authorizationFields,
            navigationMenuAuthorizationsFields,

            statusOptions,
            yesNoOptions,

            onSubmit,
            getValidationState,
            resetForm,
            changeAuthorizationValue,
            changeNavigationMenuAuthorizationValue,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
